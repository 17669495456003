import { useState } from "react";
import { NextPage } from "next";
import {
  AuthAction,
  withAuthUser,
  withAuthUserTokenSSR,
} from "next-firebase-auth";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "react-i18next";

import LoginForm from "~/components/organisms/LoginForm";
import PopupModal from "~/components/organisms/PopupModal";
import apiClient from "~/lib/apiClient";
import styles from "~/styles/IndexPage.module.scss";

const IndexPage: NextPage = () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const sendEmailLoginLink = async (email: string) => {
    await apiClient.requestLogin({ email });
    window.localStorage.setItem("emailForSignIn", email);
    setIsSubmitted(true);
  };

  return (
    <div className={styles.indexPage}>
      {isSubmitted ? (
        <PopupModal
          heading={t("login:request.successHeading")}
          onClickOut={() => setIsSubmitted(false)}
        >
          <p>{t("login:request.successBody")}</p>
        </PopupModal>
      ) : null}
      <img src="/images/moonLogoWhite.png" alt="Moon Creative Lab" />
      <LoginForm
        label={t("login:request.label")}
        onSubmit={sendEmailLoginLink}
      />
      <a
        className={styles.indexPage__helpLink}
        href="mailto:hr@mooncreativelab.com"
      >
        {t("login:common.support")}
      </a>
    </div>
  );
};

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(IndexPage);

export const getServerSideProps = withAuthUserTokenSSR({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ["login"])),
    },
  };
});
